<!-- 获取详情的接口：weg/detail -->
<!--  读数历史列表查询：weg/reading/list 参数：wegId-表id -->
<template>
  <a-card style="margin: -2em -2em 0px">
    <!-- 头部 -->
    <div style="margin: -2em -2em 0px">
      <a-card :bordered="false">
        <DescriptionList>
          <div class="titleBoxL titleBox">
            <label class="title">
              {{ baseInfo.facilityCode }} {{ baseInfo.deviceName }}
              <span v-if="baseInfo.isDeleted == 'N'" class="tagA">启用中</span>
              <span v-if="baseInfo.isDeleted == 'Y'" class="tagB">已停用</span>
            </label>
            <div class="fr">
              <a-button type="primary" @click="editItem">编辑信息</a-button>
            </div>
          </div>
          <div style="margin-left: 54px;width: 90%">
            <Description term="门店名称"
              ><label>{{ baseInfo.facilityName }}</label>
            </Description>
            <Description term="档口编号"
              ><label>{{ baseInfo.kitchenCode }}</label>
            </Description>
            <Description term="设备属性"
              ><label>{{ baseInfo.useScopeName }}</label>
            </Description>
            <Description term="设备类型"
              ><label>{{ baseInfo.typeName }}</label>
            </Description>
            <Description term="倍率"
              ><label>{{ baseInfo.ratio }}</label>
            </Description>
            <Description term="是否智能设备"
              ><label>{{ baseInfo.enableSmartName }}</label>
            </Description>
            <Description term="电表号"
              ><label>{{ baseInfo.pcode }}</label>
            </Description>
            <Description term="所属网关"
              ><label>{{ baseInfo.ccode }}</label>
            </Description>
            <Description term="创建日期">
              <label>
                {{
                  !!baseInfo.gmtCreated
                    ? moment(baseInfo.gmtCreated).format("YYYY-MM-DD")
                    : ""
                }}
              </label>
            </Description>
            <Description v-if="baseInfo.isDeleted == 'Y'" term="停用日期">
              <label>
                {{
                  !!baseInfo.gmtModified
                    ? moment(baseInfo.gmtModified).format("YYYY-MM-DD")
                    : ""
                }}
              </label>
            </Description>
            <Description term="创建人"
              ><label>{{ baseInfo.creator }}</label>
            </Description>
            <Description term="当前付费模式"
              ><label>{{ showPrepayMode(baseInfo.prepayMode) }}</label>
            </Description>
          </div>
        </DescriptionList>
      </a-card>
    </div>
    <!-- 编辑按钮 -->
    <div class="fr" style="margin: 2em 0;">
      <a-button type="primary" icon="plus" @click="addNewItem"
        >新增读数</a-button
      >
    </div>
    <!-- 切换数据请求 -->
    <a-spin :spinning="dataLoading">
      <div class="tagBox">
        <a-table
          :pagination="pagination"
          :columns="columns"
          :dataSource="tableData"
          :scroll="{ x: 800 }"
          @change="changePageSize"
        >
          <span slot="action" slot-scope="text, record" class="blueColor">
            <template>
              <a v-show="record.id > 0" @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;
              <a v-show="record.id > 0" @click="handleDelete(record)">删除</a>
            </template>
          </span>
        </a-table>
      </div>
    </a-spin>
    <!-- 修改表信息弹窗 -->
    <a-modal
      title="编辑表信息"
      :visible="showEditInfo"
      :confirmLoading="showEditInfoLoading"
      @ok="handleSaveEdit"
      @cancel="handleCancelEdit"
      destroyOnClose
    >
      <a-form layout="inline" :form="form">
        <a-row :gutter="30" style="line-height: 4">
          <a-col :md="24" :sm="24">
            <a-form-item label="设备名称">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'deviceName',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: detailValue.deviceName
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="所属门店">
              <a-select
                @change="getKitchen"
                style="width: 240px"
                v-decorator="[
                  'facilityId',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: detailValue.facilityId
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in facilityList" :key="item.id">
                  {{ item.optionText }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="this.iskitchen" :md="24" :sm="24">
            <a-form-item label="所属档口">
              <a-select
                style="width: 240px"
                v-decorator="[
                  'kitchenId',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: detailValue.kitchenId
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in kitchList" :key="item.id">
                  {{ item.code }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="设备类型">
              <a-select
                style="width: 240px"
                v-decorator="[
                  'type',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: detailValue.type
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in deviceTypeList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="设备属性">
              <a-select
                @change="changeuseScope"
                style="width: 240px"
                v-decorator="[
                  'useScope',
                  {
                    rules: [{ required: true, message: '请选择' }],
                    initialValue: detailValue.useScope
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in useScopeList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="是否智能设备">
              <a-select
                style="width: 240px"
                v-decorator="[
                  'enableSmart',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！'
                      }
                    ],
                    initialValue: detailValue.enableSmart
                  }
                ]"
                placeholder="请选择"
              >
                <a-select-option v-for="item in smartList" :key="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="电表号">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'pcode',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请输入！'
                      }
                    ],
                    initialValue: detailValue.pcode
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="所属网关">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'ccode',
                  {
                    rules: [
                      {
                        required: false,
                        message: '请输入！'
                      }
                    ],
                    initialValue: detailValue.ccode
                  }
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :md="24" :sm="24">
            <a-form-item label="倍率">
              <a-input
                style="width: 240px"
                v-decorator="[
                  'ratio',
                  {
                    rules: [
                      {
                        required: true,
                        pattern: /^[0-9]*[1-9][0-9]*$/,
                        message: '请输入正整数！'
                      }
                    ],
                    initialValue: detailValue.ratio
                  }
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 新增读数操作 -->
    <a-modal
      title="新增读数"
      :visible="showAddItem"
      :confirmLoading="showAddItemLoading"
      @ok="handleSaveAddItem"
      @cancel="handleCancelAddItem"
      destroyOnClose
    >
      <h3>{{ baseInfo.facilityCode }} {{ baseInfo.deviceName }}</h3>
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>表读数：</span>
          <a-input v-model="addItemValue.number" style="width: 200px" />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>抄表日期：</span>
          <a-date-picker
            v-model="addItemValue.momentDate"
            @change="changeMoment('showAddItem')"
            :allowClear="false"
            placeholder="请选择"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>抄表渠道：</span>
          <a-select
            style="width: 175px;margin-left: 0.5em;"
            v-model="addItemValue.channel"
            placeholder="请选择"
          >
            <a-select-option v-for="item in channelList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 编辑读数操作 -->
    <a-modal
      title="编辑读数"
      :visible="showEditItem"
      :confirmLoading="showEditItemLoading"
      @ok="handleSaveEditItem"
      @cancel="handleCancelEditItem"
      destroyOnClose
    >
      <h3>{{ baseInfo.facilityCode }} {{ baseInfo.deviceName }}</h3>
      <a-row :gutter="30" style="line-height: 4">
        <a-col :md="24" :sm="24">
          <span>表读数：</span>
          <a-input v-model="editItemValue.number" style="width: 200px" />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>抄表日期：</span>
          <a-date-picker
            v-model="editItemValue.momentDate"
            :allowClear="false"
            @change="changeMoment('showEditItem')"
            placeholder="请选择"
          />
        </a-col>
        <a-col :md="24" :sm="24">
          <span>抄表渠道：</span>
          <a-select
            style="width: 175px;margin-left: 0.5em;"
            v-model="editItemValue.channel"
            placeholder="请选择"
          >
            <a-select-option v-for="item in channelList" :key="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </a-modal>
    <!-- 删除提示 -->
    <a-modal
      title="确认删除整条记录？"
      :visible="showDelete"
      :confirmLoading="showDeleteLoading"
      @ok="handleDeleteItem"
      @cancel="handleCancelDeleteItem"
      destroyOnClose
    >
      <h3><span class="red">该操作无法撤回</span>，你还要继续么？</h3>
    </a-modal>
  </a-card>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
const { Description } = DescriptionList;
import moment from "moment";
import axios from "axios";
  
import { defaultPagination } from "@/utils/pagination";

export default {
  name: "hydropowerConfigureDetail",
  components: {
    DescriptionList,
    Description
  },
  data() {
    return {
      id: this.$route.query.id,
      baseInfo: {},
      // 数据loading
      dataLoading: false,
      // 分页
      pagination: defaultPagination(() => {}),
      pageSize: 20,
      current: 1,
      // 表头
      columns: [
        {
          title: "读数日期",
          customRender: (text, row, index) => {
            if (row.gmtCreatedLong) {
              return (
                <span>{moment(row.gmtCreatedLong).format("YYYY-MM-DD")}</span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "读数",
          dataIndex: "number",
          key: "number"
        },
        {
          title: "抄表渠道",
          dataIndex: "channelName",
          key: "channelName"
        },
        {
          title: "抄表方式",
          dataIndex: "sourceName",
          key: "sourceName"
        },
        {
          title: "最后修改时间",
          customRender: (text, row, index) => {
            if (row.gmtModifiedLong) {
              return (
                <span>{moment(row.gmtModifiedLong).format("YYYY-MM-DD")}</span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "最后修改",
          dataIndex: "modifier",
          key: "modifier"
        },
        {
          title: "操作",
          key: "action",
          width: "200px",
          scopedSlots: { customRender: "action" }
        }
      ],
      // 数据
      tableData: [{ id: 1, repairExtent: 1, repairStatus: 2 }],
      // 编辑表信息
      detailValue: {},
      form: this.$form.createForm(this),
      showEditInfo: false,
      showEditInfoLoading: false,
      // 门店列表
      facilityList: [],
      //设备类型
      deviceTypeList: [
        { id: 1, name: "水表" },
        { id: 2, name: "电表" },
        { id: 3, name: "燃气表" }
      ],
      // 是否智能设备
      useScopeList: [
        { id: 1, name: "公摊" },
        { id: 2, name: "自用" },
        { id: 3, name: "总表" }
      ],
      // 是否智能设备
      smartList: [
        { id: "Y", name: "是" },
        { id: "N", name: "否" }
      ],
      // 档口的显示
      iskitchen: false,
      // 新增读数
      addItemValue: {},
      showAddItem: false,
      showAddItemLoading: false,
      channelList: [
        { id: 1, name: "正常抄表" },
        { id: 2, name: "离场登记抄表" },
        { id: 3, name: "开业审批抄表" }
      ],
      // 编辑读数
      editItemValue: {},
      showEditItem: false,
      showEditItemLoading: false,
      // 删除
      deleteId: "",
      showDelete: false,
      showDeleteLoading: false,
      // 付费模式
      prepayModeList: [
        {id: 1, name: "后付费"},
        {id: 2, name: "预付费"}
      ]
    };
  },
  created() {
    this.init();
  },
  computed: {
    showPrepayMode(value) {
      return function(value) {
        let res = "未知";
        this.prepayModeList.forEach(e => {
          if (e.id === value) {
            res = e.name;
          }
        });
        return res;
      };
    }
  },
  methods: {
    showInfo(record) {
      console.log(this.baseInfo.prepayMode);
      console.log(record);
    },
    // comm
    moment,
    // 根据所在门店获取关联档口 获取厨房档口列表
    getKitchen(val) {
      // 如果查询到的档口为空，清空它们之前的选中
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        kitchenId: ""
      });
      this.kitchList = [];
      axios({
        method: "GET",
        url:   this.$baseUrl + "/kitchen/querySelect",
        params: {
          facilityId: val
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.kitchList = res.data.rows || [];
          if (this.showEditInfo) {
            this.showEditInfo = false;
            this.showEditInfo = true;
          }
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 所属门店
    getFacility() {
      axios({
        url:   this.$baseUrl + "/facility/querySelect",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    // 选择公摊和自用之后的操作 是否显示档口
    changeuseScope(val) {
      if (val == 1 || val == 3) {
        this.iskitchen = false;
        // 如果查询到的档口为空，清空它们之前的选中
        const {
          form: { setFieldsValue }
        } = this;
        setFieldsValue({
          kitchenId: ""
        });
      } else {
        this.iskitchen = true;
      }
      // 判断是否在编辑中
      if (this.showEditInfo) {
        this.showEditInfo = false;
        this.showEditInfo = true;
      }
    },
    // 初始化
    init() {
      this.getFacility();
      this.getInfo();
      this.initTable();
    },
    getInfo() {
      // 获取相关信息接口
      // weg/detail
      axios({
        url:   this.$endpoints.UTILITY_GET_WEG_METER_DETAIL,
        method: "GET",
        params: {
          id: this.id
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.baseInfo = res.data.obj || {};
          this.getKitchen(this.baseInfo.facilityId);
          this.changeuseScope(this.baseInfo.useScope);
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // initTable 表格初始化函数
    initTable() {
      this.dataLoading = true;
      // 读数历史列表查询：weg/reading/list 参数：wegId-表id
      let id = this.id;
      axios({
        url:   this.$endpoints.UTILITY_LIST_WEG_METER_READINGS,
        method: "GET",
        params: {
          rows: this.pageSize || 20,
          page: this.current || 1,
          wegId: id
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.tableData = res.data.rows || [];
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.dataLoading = false;
      });
    },
    // 分页
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.initTable();
    },
    // 编辑表信息
    editItem() {
      this.detailValue = Object.assign({}, this.baseInfo);
      this.showEditInfo = true;
    },
    // 保存表信息
    handleSaveEdit() {
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          let val = Object.assign({ id: this.detailValue.id }, values);
          this.handleSaveSubmit(val);
        }
      });
    },
    // 提交
    handleSaveSubmit(val) {
      // if (val.enableSmart == "Y" && (!val.pcode || !ccode)) {
      //   this.$message.error(`智能电表,电表号与所属网管有误！`);
      //   this.showEditItemLoading = false;
      //   retrun;
      // }
      axios({
        method: "POST",
        url:   this.$baseUrl + "/weg/modify",
        headers: {
          Accept: "*/*"
        },
        data: {
          ...val
        }
      })
        .then(res => {
          if (res.data.success) {
            this.getInfo();
            this.$message.success("修改成功！");
            this.showEditInfo = false;
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 取消
    handleCancelEdit() {
      this.showEditInfo = false;
    },

    //验证提交
    verifyInfo(info) {
      if (!info.number || isNaN(info.number) || info.number < 0) {
        this.$message.error(`读数有误！`);
        return false;
      }
      if (!info.momentDate) {
        this.$message.error(`抄表日期有误！`);
        return false;
      }
      if (!info.channel) {
        this.$message.error(`请选择渠道！`);
        return false;
      }
      return true;
    },

    // 新增读数信息
    addNewItem() {
      let id = this.id;
      this.addItemValue = {};
      this.addItemValue.wegId = this.id;
      this.addItemValue.momentDate = null;
      this.showAddItem = true;
    },
    // 新增保存表信息
    handleSaveAddItem() {
      if (this.verifyInfo(this.addItemValue)) {
        this.addItemValue.gmtCreated = moment(
          this.addItemValue.momentDate
        ).format("YYYY-MM-DD");
        axios({
          url:   this.$baseUrl + "weg/meter/save",
          method: "POST",
          data: {
            ...this.addItemValue
          }
        }).then(res => {
          if (res.data.returnCode == "200") {
            console.log(res.data.obj);
            this.$message.info(`添加成功！`);
            this.showAddItem = false;
            this.pageSize = 20;
            this.current = 1;
            this.initTable();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        });
      }
    },
    // 取消保存表信息
    handleCancelAddItem() {
      this.showAddItem = false;
    },

    // 编辑读数信息
    handleEdit(val) {
      this.editItemValue = Object.assign({ wegId: this.id }, val);
      this.editItemValue.momentDate = val.gmtCreatedLong
        ? moment(val.gmtCreatedLong)
        : null;
      this.showEditItem = true;
    },
    // 保存编辑读数
    handleSaveEditItem() {
      if (this.verifyInfo(this.editItemValue)) {
        this.editItemValue.gmtCreated = moment(
          this.editItemValue.momentDate
        ).format("YYYY-MM-DD");
        axios({
          url:   this.$baseUrl + "weg/meter/modify",
          method: "POST",
          data: {
            ...this.editItemValue
          }
        }).then(res => {
          if (res.data.returnCode == "200") {
            this.$message.info(`修改成功！`);
            this.showEditItem = false;
            this.initTable();
          } else {
            this.$message.error(`${res.data.returnMsg}`);
          }
        });
      }
    },
    // 保存编辑读数
    handleCancelEditItem() {
      this.showEditItem = false;
    },

    // 弹窗上日期变化
    changeMoment(key) {
      this[key] = false;
      this[key] = true;
    },

    // 删除读数信息
    handleDelete(val) {
      this.deleteId = val.id || "";
      this.showDelete = true;
    },
    // 删除整条
    handleDeleteItem() {
      axios({
        url:   this.$baseUrl + "weg/meter/remove",
        method: "POST",
        data: {
          id: this.deleteId
        }
      }).then(res => {
        if (res.data.returnCode == "200") {
          this.$message.info(`删除成功！`);
          this.showDelete = false;
          this.initTable();
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 取消删除整条
    handleCancelDeleteItem() {
      this.showDelete = false;
    }
  }
};
</script>

<style lang="less" scoped>
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tagBox {
  background-color: #fff;
}
.titleBoxL {
  padding-left: 2.5em !important;
}
.titleBox {
  padding: 1em;
}
.title {
  font-size: 1.5em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5em;
}
/* 维修中 */
.tagA {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: rgba(82, 196, 26, 1);
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
/* 验收中 */
.tagB {
  font-size: 0.6em;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.85);
  line-height: 0.6em;
  background-color: red;
  color: #fff;
  padding: 0.2em 0.4em;
  margin-left: 1em;
  border-radius: 0.4em;
}
.red {
  color: red;
}
</style>
